import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>404: Page Not Found</h1>
    <hr />
    <p>Sorry! This page is not a dingus we've made yet. </p>
    <Link to="/">
      <strong>← Back to home</strong>
    </Link>
  </Layout>
)

export default NotFoundPage
